//
// page header
// --------------------------------------------------

@import 'variables';

.#{$page-header-class} {
  $this: &;

  display: flex;
  align-items: center;
  height: rem(80px);
  background-color: color('purple', '800');
  padding-left: rem(52px);
  padding-right: rem(52px);
  border-bottom: 1px solid color('gray', '500');

  //
  // Elements
  // -------------------------

  &__title {
    margin: 0;
    margin-right: auto;
    color: color('white');
    font-size: rem(28px);
  }

  //
  // Modifiers
  // -------------------------
}
