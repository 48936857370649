//
// pagination
// --------------------------------------------------

@import 'pagination-link';
@import 'variables';

.#{$pagination-class} {
  $this: &;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;

  //
  // Elements
  // -------------------------

  &__item {
    & + & {
      margin-left: rem(16px);
    }
  }

  //
  // Modifiers
  // -------------------------
}
