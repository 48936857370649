//
// form
// --------------------------------------------------

@import 'variables';

.#{$form-class} {
  $this: &;

  //
  // Elements
  // -------------------------

  &__header {
    margin-bottom: rem(24px);
  }

  &__title {
    margin: 0;
    text-align: center;
  }

  &__alert {
    margin-bottom: rem(16px);
  }

  &__field {
    margin-bottom: rem(8px);
  }

  //
  // Modifiers
  // -------------------------

  &--login {
    width: rem(400px);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
