//
// Alert
// --------------------------------------------------

@import 'variables';

.#{$alert-class} {
  $this: &;

  position: relative;
  display: none;
  padding: rem(24px) rem(16px);
  border-radius: rem(2px);
  align-items: flex-start;

  //
  // Elements
  // -------------------------

  &__icon {
    font-size: rem(24px);
    margin-right: rem(12px);
    flex-shrink: 0;
  }

  &__body {
    flex-grow: 1;
  }

  &__title {
    @include interpolate(font-size, rem(14px), rem(18px));

    margin-top: em(2px, 18px);
    margin-bottom: em(8px, 18px);
    font-weight: 700;
    color: color('gray', '900');
  }

  &__message {
    p {
      margin: 0;
    }
  }

  &__actions {
    flex-shrink: 0;
    margin-left: rem(12px);
  }

  &__close-button {
    font-size: rem(14px);
    background-color: transparent;
    margin-left: rem(12px);
    flex-shrink: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: color('gray', '600');

    &:hover {
      color: color('gray', '900');
    }
  }

  //
  // States
  // -------------------------

  &.is-success,
  .is-success > & {
    display: flex;
    background-color: color('green', '100');
    border-color: color('green', '100');
    color: color('gray', '900');

    #{$this}__icon {
      color: color('green', '900');
    }
  }

  &.is-info,
  .is-info > & {
    display: flex;
    background-color: color('blue', '100');
    border-color: color('blue', '100');
    color: color('gray', '900');

    #{$this}__icon {
      color: color('blue', '900');
    }
  }

  &.is-warning,
  .is-warning > & {
    display: flex;
    background-color: color('orange', '100');
    border-color: color('orange', '100');
    color: color('gray', '900');

    #{$this}__icon {
      color: color('orange', '900');
    }
  }

  &.is-error,
  .is-error > & {
    display: flex;
    background-color: color('red', '100');
    border-color: color('red', '100');
    color: color('gray', '900');

    #{$this}__icon {
      color: color('red', '900');
    }
  }
}
