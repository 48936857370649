//
// horizontal rule
// --------------------------------------------------

@import 'variables';

hr {
  border-width: $hr-border-width;
  border-style: $hr-border-style;
  border-color: $hr-border-color;
}
