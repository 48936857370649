//
// Hamburger
//
// --------------------------------------------------

@import './keyframes';
@import './variables';

.#{$hamburger-class} {
  $this: &;

  position: relative;
  display: inline-flex;
  width: $hamburger-width;
  min-height: $hamburger-width;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &__line {
    position: relative;
    display: inline-block;
    width: rem($hamburger-line-width);
    height: 2px;
    background-color: $hamburger-line-color;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $hamburger-line-color;
      content: '';
    }

    &::before {
      animation: hamburger-before-passive $hamburger-transition-duration;
      transform: translateY(-300%);
    }

    &::after {
      animation: hamburger-after-passive $hamburger-transition-duration;
      transform: translateY(300%);
    }
  }

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------

  //
  // Active State
  // -------------------------

  &.is-active {
    #{$this}__line {
      background-color: transparent;

      &::before {
        animation: hamburger-before-active $hamburger-transition-duration;
        animation-fill-mode: forwards;
      }

      &::after {
        animation: hamburger-after-active $hamburger-transition-duration;
        animation-fill-mode: forwards;
      }
    }
  }
}
