//
// field\stext
// --------------------------------------------------

@import 'variables';

.#{$field-text-class} {
  $this: &;

  display: block;
  font-size: rem(14px);

  &.is-valid,
  .is-valid > & {
    color: color('green', '800');
  }

  &.is-invalid,
  .is-invalid > & {
    color: color('red', '800');
  }

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------
}
