//
// sidebar
// --------------------------------------------------

@import 'variables';

.#{$sidebar-class} {
  $this: &;

  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: rem($g-sidebar-width);
  background-color: color('purple', '800');
  border-right: 1px solid color('gray', '500');

  //
  // Elements
  // -------------------------

  &__divider {
    display: block;
    width: 100%;
    height: 1px;
    background-color: color('gray', '500');
    margin-top: rem(24px);
    margin-bottom: rem(24px);
  }

  &__logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(80px);
    border-bottom: 1px solid color('gray', '500');
    margin-bottom: rem(24px);
    flex-shrink: 0;
  }

  &__menu {
    flex-grow: 1;
  }

  //
  // Modifiers
  // -------------------------
}
