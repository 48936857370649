//
// auto complete
// --------------------------------------------------

@import 'auto-complete-overlay';
@import 'auto-complete-menu';
@import 'variables';

.#{$auto-complete-class} {
  $this: &;

  position: relative;

  //
  // Elements
  // -------------------------

  &__input {
    .is-focus & {
      border-color: color('gray', '300');
    }
  }

  //
  // Modifiers
  // -------------------------
}
