//
// Input Group
// --------------------------------------------------

@import 'variables';

.#{$input-group-class} {
  $this: &;

  display: flex;
  position: relative;

  //
  // Elements
  // -------------------------

  &__icon {
    position: absolute;
    top: 0;
    display: inline-flex;
    width: rem(46px);
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: color('gray', '700');
    font-size: 1em;

    &:focus {
      outline: 0;
    }

    svg {
      display: block;
    }
  }

  //
  // Modifiers
  // -------------------------

  &--icon-left {
    #{$this}__input {
      padding-left: rem(48px);
    }

    #{$this}__icon {
      left: 0;
    }
  }

  &--icon-right {
    #{$this}__input {
      padding-right: rem(48px);
    }

    #{$this}__icon {
      right: 0;
      left: auto;
    }
  }
}
