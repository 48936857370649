//
// pagination link
// --------------------------------------------------

@import 'variables';

.#{$pagination-link-class} {
  $this: &;

  display: block;
  min-width: em($pagination-link-size, $pagination-link-font-size);
  padding: 0;
  border: 0;
  background-color: color('light-gray', '500');
  border-radius: em($pagination-link-border-radius, $pagination-link-font-size);
  color: color('gray', '500');
  font-size: em($pagination-link-font-size);
  line-height: em($pagination-link-size, $pagination-link-font-size);
  text-align: center;

  &.is-active,
  .is-active > & {
    background-color: color('green', '500');
    color: white;
  }

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------

  &--clickable {
    cursor: pointer;
  }
}
