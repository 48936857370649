//
// Input Size
//
// @param $font-size
// @param $font-weight
// @param $padding-vertical
// @param $padding-horizontal
// @param $outline
// @param $outline-width
// @param $outline-offset
// @param $border-radius
// @param $border-width
// @param $height
// -------------------------
@mixin input-size(
  $font-size: 16px,
  $font-weight: null,
  $padding-vertical: 8px,
  $padding-horizontal: 16px,
  $outline: false,
  $outline-width: null,
  $outline-offset: null,
  $border-radius: null,
  $border-width: null,
  $height: auto
) {
  @if $height == auto {
    height: auto;
  }

  @if $height != auto {
    height: rem($height);
  }

  border-radius: $border-radius;
  border-width: $border-width;
  font-weight: $font-weight;
  font-size: rem($font-size);
  padding: em($padding-vertical, $font-size) em($padding-horizontal, $font-size);

  @if ($outline == true) {
    outline-width: $outline-width;
    outline-offset: $outline-offset;
  }
}
