//
// analytics
// --------------------------------------------------

@import 'variables';

.#{$analytics-class} {
  $this: &;

  padding: rem(32px) rem(48px);

  //
  // Elements
  // -------------------------

  &__content {
    background-color: color('purple', '800');
    border-radius: rem(20px);
    padding: rem(52px) rem(92px);
  }

  &__table {
    &:not(:first-child) {
      margin-top: rem(32px);
    }
  }

  //
  // Modifiers
  // -------------------------
}
