///
// Grid
///

@mixin make-cssgrid($grid-columns: 12) {
  @for $i from 1 through $grid-columns {
    .grid__col-#{$i} {
      grid-column: auto / span $i;
    }
  }

  @for $i from 1 through ($grid-columns) {
    .grid__col-start-#{$i} {
      grid-column-start: $i;
    }
  }

  @each $breakpoint, $value in $breakpoint-sizes {
    @include breakpoint-up($breakpoint) {
      @for $i from 1 through $grid-columns {
        .grid__col-#{$breakpoint}-#{$i} {
          grid-column: auto / span $i;
        }
      }

      @for $i from 1 through ($grid-columns) {
        .grid__col-start-#{$breakpoint}-#{$i} {
          grid-column-start: $i;
        }
      }
    }
  }
}
