//
// image with text
// --------------------------------------------------

@import 'variables';

.#{$image-with-text-class} {
  $this: &;

  @include breakpoint-up('lg') {
    display: flex;
    flex-wrap: wrap;
  }

  //
  // Elements
  // -------------------------

  &__image {
    flex: 0 0 50%;
  }

  &__content {
    flex: 0 0 50%;
    padding: rem(24px) 0;

    @include breakpoint-up('lg') {
      padding: rem(58px);
    }
  }

  //
  // Modifiers
  // -------------------------

  &--align-top {
    align-items: flex-start;
  }

  &--align-center {
    align-items: center;
  }

  &--align-bottom {
    align-items: flex-end;
  }

  &--revert {
    flex-direction: row-reverse;
  }
}
