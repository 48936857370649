//
// Font Face
//
// @param {String} $font-family
// @param {String} $font-folder
// @param {String} $font-file-name
// @param {String} $font-weight
// @param {String} $font-style
//
// @example scss - example
//   @include font-faces('Helvetica', 'Helvetica', 'Helvetica-Regular', 'normal', 'normal');
// @example css - output
//   @font-face {
//     font-family: 'Helvetica';
//     font-style: italic;
//     font-weight: 400;
//     src: url('fonts/Helvetica/Helvetica-Regular.woff2') format('woff2'),
//       url('fonts/Helvetica/Helvetica-Regular.woff') format('woff'),
//       url('fonts/Helvetica/Helvetica-Regular.ttf') format('truetype');
//   }

@mixin font-face(
  $font-family,
  $font-folder-name,
  $font-file-name,
  $font-weight: normal,
  $font-style: normal
) {
  @font-face {
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: swap;
    src: url('#{$font-face-root-path}#{$font-folder-name}/#{$font-file-name}.woff2') format('woff2'),
      url('#{$font-face-root-path}#{$font-folder-name}/#{$font-file-name}.woff') format('woff'),
      url('#{$font-face-root-path}#{$font-folder-name}/#{$font-file-name}.ttf') format('truetype'); // Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
  }
}
