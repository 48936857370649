//
// orders
// --------------------------------------------------

@import 'variables';

.#{$orders-class} {
  $this: &;

  //
  // Elements
  // -------------------------

  &__tab {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &__content {
    padding: rem(54px) rem(66px);
    max-width: rem(900px);
  }

  &__header {
    margin-bottom: rem(30px);
    border-bottom: 1px solid color('light-gray', '400');
  }

  &__title {
    font-size: rem(28px);
    font-weight: 400;
    color: color('gray', '900');
    margin-top: 0;
    margin-bottom: rem(24px);
  }

  &__section {
    padding-bottom: rem(30px);
    margin-bottom: rem(30px);
    border-bottom: 1px solid color('light-gray', '400');
  }

  &__section-title {
    font-size: rem(20px);
    font-weight: 400;
    color: color('gray', '900');
    margin-top: 0;
    margin-bottom: rem(30px);
  }

  &__product-option-select {
    &:not(:last-child) {
      margin-right: rem(36px);
    }
  }

  &__form-actions {
    text-align: right;
  }

  //
  // Modifiers
  // -------------------------
}
