//
// Code
// -------------------------

@import 'variables';

pre,
code,
kbd,
samp {
  font-family: $code-font-family-monospace;
}
