//
// drawer
// --------------------------------------------------

@import 'variables';

.#{$drawer-class} {
  $this: &;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $drawer-zindex;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: $drawer-transition-duration;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &.is-active > #{$this}__wrapper {
    transform: translate3d(0, 0, 0);
  }

  //
  // Elements
  // -------------------------

  &__close-button {
    display: inline-flex;
    position: fixed;
    right: rem(16px);
    top: rem(16px);
    background-color: transparent;
    border: 0;
    padding: rem(10px);
    font-size: rem(20px);
    margin: 0;
    border-radius: 50%;
    color: color('gray', '400');
    cursor: pointer;
    transition-property: color, background-color;
    transition-duration: $drawer-transition-duration;
    background-color: rgba(color('white'), 0.025);

    &:hover {
      background-color: rgba(color('black'), 0.025);
      color: color('gray', '800');
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    padding: 0;
    margin: 0;
    background-color: rgba(color('black'), 0.5);
  }

  &__wrapper {
    position: absolute;
    transform: translate3d(10%, 0, 0);
    transition-property: transform;
    transition-duration: $drawer-transition-duration;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: color('white');
  }

  &__body {
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: rem(40px);
  }

  //
  // Modifiers
  // -------------------------

  &--placement {
    &-left #{$this}__wrapper {
      left: 0;
      top: 0;
      bottom: 0;
      width: rem(800px);
      transform: translate3d(-10%, 0, 0);
    }

    &-right #{$this}__wrapper {
      right: 0;
      top: 0;
      bottom: 0;
      width: rem(800px);
      transform: translate3d(10%, 0, 0);
    }

    &-top #{$this}__wrapper {
      top: 0;
      right: 0;
      left: 0;
      height: rem(400px);
      transform: translate3d(0, -10%, 0);
    }

    &-bottom #{$this}__wrapper {
      bottom: 0;
      right: 0;
      left: 0;
      height: rem(400px);
      transform: translate3d(0, 10%, 0);
    }
  }
}
