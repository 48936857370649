//
// Field
// --------------------------------------------------

@import 'variables';

.#{$field-class} {
  border: 0;
  padding: 0;
  margin: 0;

  //
  // Elements
  // -------------------------

  &__label {
    display: block;
    margin-bottom: rem(10px);
    color: color('gray', '400');

    &.is-required,
    .is-required > & {
      &::after {
        content: ' *';
        color: color('red', '700');
      }
    }
  }

  &__field-text {
    margin-top: rem(4px);
  }

  //
  // Modifiers
  // -------------------------
}
