//
// tracking
// --------------------------------------------------

@import 'variables';

.#{$tracking-class} {
  $this: &;

  //
  // Elements
  // -------------------------

  &__content {
    padding: rem(32px) rem(48px);
  }

  &__search {
    margin-bottom: rem(32px);
    width: rem(515px);
    max-width: 100%;
  }

  &__table {
    max-width: 100%;
  }

  &__pagination {
    margin-top: rem(32px);
  }

  //
  // Modifiers
  // -------------------------
}
