//
// status history
// --------------------------------------------------

@import 'variables';

.#{$status-history-class} {
  $this: &;

  //
  // Elements
  // -------------------------

  &__header {
    position: relative;
    padding-top: rem(16px);
    padding-bottom: rem(16px);
  }

  &__title {
    text-align: center;
  }

  &__close-button {
    position: absolute;
    top: 50%;
    right: rem(16px);
    border: 0;
    padding: rem(8px);
    transform: translateY(-50%);
    background-color: transparent;
  }

  &__table {
    margin-top: rem(16px);
    min-width: auto;

    @include interpolate(font-size, rem(14px), rem(16px));

    thead {
      background-image: get($gradient-values, 'gradient-01');
    }

    thead th {
      color: color('white');
    }
  }

  //
  // Modifiers
  // -------------------------
}
