//
// Base
// -------------------------

@import 'variables';

html {
  box-sizing: border-box;
  font-size: $base-font-size-root;

  @if $base-font-antialiasing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $base-font-family-primary;
  font-size: rem($base-font-size-body);
  line-height: $base-font-line-height;
  color: $base-body-color;
  background-color: $base-body-background-color;
}
