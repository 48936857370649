//
// process
// --------------------------------------------------

@import 'variables';

.#{$page-process-class} {
  $this: &;

  height: 100%;
  display: flex;
  flex-direction: column;

  //
  // Elements
  // -------------------------

  &__order-id,
  &__title,
  &__subtitle,
  &__step,
  &__order-type {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__order-id {
    font-size: rem(24px);
    padding: rem(12px);
  }

  &__order-timeline {
    position: absolute;
    right: rem(20px);
    top: rem(4px);
    display: inline-flex;
    padding: rem(14px);
    background-color: color('green', '700');
    border-radius: 50%;
  }

  &__title {
    font-size: rem(24px);
    padding: rem(22px);
  }

  &__subtitle {
    font-size: rem(18px);
    padding: rem(20px);
  }

  &__step {
    font-size: rem(18px);
    padding: rem(20px);
  }

  &__order-type {
    font-size: rem(18px);
    padding: rem(20px);
    background-color: color('red', '100');
    color: color('red', '700');
  }

  &__content {
    flex-grow: 1;
    padding: rem(32px) rem(24px);
  }

  &__category-buttons {
    display: flex;
    align-items: center;
    gap: rem(18px);
  }

  &__category-button {
    flex-grow: 1;
  }

  &__order-details {
    margin-top: rem(32px);
  }

  &__order-details-item {
    display: flex;
    align-items: center;
    font-size: rem(20px);

    &:not(:first-child) {
      margin-top: rem(24px);
    }
  }

  &__order-details-label {
    min-width: rem(84px);
    color: color('gray', '400');
  }

  &__name {
    margin-top: rem(32px);
  }

  &__note {
    margin-top: rem(32px);
  }

  &__actions {
    border-top: 1px solid color('light-gray', '400');
    padding: rem(32px) rem(24px);
    flex-shrink: 0;
  }

  &__action-button {
    &:not(:first-child) {
      margin-top: rem(20px);
    }
  }

  //
  // Modifiers
  // -------------------------

  &--theme {
    &-green {
      #{$this}__order-id {
        border-bottom: 1px solid color('green', '700');
      }

      #{$this}__order-id,
      #{$this}__title {
        background-color: color('green', '800');
        color: color('white');
      }

      #{$this}__subtitle,
      #{$this}__step {
        background-color: color('green', '700');
        color: color('white');
      }

      #{$this}__step {
        border-top: 1px solid color('green', '800');
      }
    }

    &-purple {
      #{$this}__order-id {
        border-bottom: 1px solid color('purple', '700');
      }

      #{$this}__order-id,
      #{$this}__title {
        background-color: color('purple', '800');
        color: color('white');
      }

      #{$this}__subtitle,
      #{$this}__step {
        background-color: color('purple', '700');
        color: color('white');
      }

      #{$this}__step {
        border-top: 1px solid color('purple', '800');
      }
    }

    &-yellow {
      #{$this}__order-id {
        border-bottom: 1px solid color('yellow', '700');
      }

      #{$this}__order-id,
      #{$this}__title {
        background-color: color('yellow', '800');
        color: color('white');
      }

      #{$this}__subtitle,
      #{$this}__step {
        background-color: color('yellow', '700');
        color: color('white');
      }

      #{$this}__step {
        border-top: 1px solid color('yellow', '800');
      }
    }

    &-red {
      #{$this}__order-id {
        border-bottom: 1px solid color('red', '700');
      }

      #{$this}__order-id,
      #{$this}__title {
        background-color: color('red', '800');
        color: color('white');
      }

      #{$this}__subtitle,
      #{$this}__step {
        background-color: color('red', '700');
        color: color('white');
      }

      #{$this}__step {
        border-top: 1px solid color('red', '800');
      }
    }
  }
}
