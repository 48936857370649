//
// Button
// -------------------------

@import 'mixins/button-base';
@import 'mixins/button-theme';
@import 'mixins/button-size';
@import 'keyframes/loader';
@import 'variables';

.#{$button-class} {
  @include button-base(
    $line-height: 1.5,
    $transition: true,
    $outline: true,
    $border-style: solid,
    $transition-duration: 0.3s,
    $text-transform: uppercase
  );
  @include button-theme(
    $color: color('gray', '900'),
    $color-hover: color('gray', '900'),
    $color-active: color('gray', '900'),
    $color-focus: color('gray', '900'),
    $color-disabled: color('gray', '200'),
    $background-color: color('light-gray', '300'),
    $background-color-hover: color('light-gray', '400'),
    $background-color-active: color('light-gray', '400'),
    $background-color-focus: color('light-gray', '400'),
    $background-color-disabled: color('light-gray', '200'),
    $border-color: color('light-gray', '300'),
    $border-color-hover: color('light-gray', '400'),
    $border-color-active: color('light-gray', '400'),
    $border-color-focus: color('light-gray', '400'),
    $border-color-disabled: color('light-gray', '200'),
    $outline: true,
    $outline-color: transparent,
    $outline-color-hover: transparent,
    $outline-color-active: transparent,
    $outline-color-focus: transparent,
    $outline-color-disabled: transparent
  );
  @include button-size(
    $font-size: 14px,
    $font-weight: 400,
    $padding-vertical: 14px,
    $padding-horizontal: 35px,
    $border-radius: 48px,
    $border-width: 1px,
    $outline: true,
    $outline-width: 1px,
    $outline-offset: 0
  );

  //
  // Elements
  // -------------------------

  &__icon {
    &--left {
      margin-right: rem(4px);
    }

    &--right {
      margin-left: rem(4px);
    }
  }

  //
  // Modifiers
  // -------------------------

  &--white {
    @include button-theme(
      $color: color('gray', '900'),
      $color-hover: color('gray', '900'),
      $color-active: color('gray', '900'),
      $color-focus: color('gray', '900'),
      $color-disabled: color('light-gray', '800'),
      $background-color: color('white'),
      $background-color-hover: color('light-gray', '200'),
      $background-color-active: color('light-gray', '200'),
      $background-color-focus: color('light-gray', '200'),
      $background-color-disabled: color('light-gray', '200'),
      $border-color: color('white'),
      $border-color-hover: color('white'),
      $border-color-active: color('white'),
      $border-color-focus: color('white'),
      $border-color-disabled: color('white'),
      $outline: true,
      $outline-color: transparent,
      $outline-color-hover: transparent,
      $outline-color-active: transparent,
      $outline-color-focus: transparent,
      $outline-color-disabled: transparent
    );

    letter-spacing: em(2.5px, 14px);
  }

  &--green,
  &--primary {
    @include button-theme(
      $color: color('white'),
      $color-hover: color('white'),
      $color-active: color('white'),
      $color-focus: color('white'),
      $color-disabled: color('white'),
      $background-color: color('green', '500'),
      $background-color-hover: color('green', '600'),
      $background-color-active: color('green', '600'),
      $background-color-focus: color('green', '600'),
      $background-color-disabled: color('green', '300'),
      $border-color: color('green', '500'),
      $border-color-hover: color('green', '600'),
      $border-color-active: color('green', '600'),
      $border-color-focus: color('green', '600'),
      $border-color-disabled: color('green', '300'),
      $outline: true,
      $outline-color: transparent,
      $outline-color-hover: transparent,
      $outline-color-active: transparent,
      $outline-color-focus: transparent,
      $outline-color-disabled: transparent
    );

    letter-spacing: em(2.5px, 14px);
  }

  &--green-outline,
  &--primary-outline {
    @include button-theme(
      $color: color('green', '500'),
      $color-hover: color('white'),
      $color-active: color('white'),
      $color-focus: color('white'),
      $color-disabled: color('green', '300'),
      $background-color: transparent,
      $background-color-hover: color('green', '500'),
      $background-color-active: color('green', '500'),
      $background-color-focus: color('green', '500'),
      $background-color-disabled: transparent,
      $border-color: color('green', '500'),
      $border-color-hover: color('green', '500'),
      $border-color-active: color('green', '500'),
      $border-color-focus: color('green', '500'),
      $border-color-disabled: color('green', '300'),
      $outline: true,
      $outline-color: transparent,
      $outline-color-hover: transparent,
      $outline-color-active: transparent,
      $outline-color-focus: transparent,
      $outline-color-disabled: transparent
    );

    letter-spacing: em(2.5px, 14px);
  }

  &--blue,
  &--info {
    @include button-theme(
      $color: color('white'),
      $color-hover: color('white'),
      $color-active: color('white'),
      $color-focus: color('white'),
      $color-disabled: color('white'),
      $background-color: color('blue', '500'),
      $background-color-hover: color('blue', '600'),
      $background-color-active: color('blue', '600'),
      $background-color-focus: color('blue', '600'),
      $background-color-disabled: color('blue', '300'),
      $border-color: color('blue', '500'),
      $border-color-hover: color('blue', '600'),
      $border-color-active: color('blue', '600'),
      $border-color-focus: color('blue', '600'),
      $border-color-disabled: color('blue', '300'),
      $outline: true,
      $outline-color: transparent,
      $outline-color-hover: transparent,
      $outline-color-active: transparent,
      $outline-color-focus: transparent,
      $outline-color-disabled: transparent
    );

    letter-spacing: em(2.5px, 14px);
  }

  &--blue-outline,
  &--info-outline {
    @include button-theme(
      $color: color('blue', '500'),
      $color-hover: color('white'),
      $color-active: color('white'),
      $color-focus: color('white'),
      $color-disabled: color('blue', '300'),
      $background-color: transparent,
      $background-color-hover: color('blue', '500'),
      $background-color-active: color('blue', '500'),
      $background-color-focus: color('blue', '500'),
      $background-color-disabled: transparent,
      $border-color: color('blue', '500'),
      $border-color-hover: color('blue', '500'),
      $border-color-active: color('blue', '500'),
      $border-color-focus: color('blue', '500'),
      $border-color-disabled: color('blue', '300'),
      $outline: true,
      $outline-color: transparent,
      $outline-color-hover: transparent,
      $outline-color-active: transparent,
      $outline-color-focus: transparent,
      $outline-color-disabled: transparent
    );

    letter-spacing: em(2.5px, 14px);
  }

  &--red,
  &--danger {
    @include button-theme(
      $color: color('white'),
      $color-hover: color('white'),
      $color-active: color('white'),
      $color-focus: color('white'),
      $color-disabled: color('white'),
      $background-color: color('red', '500'),
      $background-color-hover: color('red', '600'),
      $background-color-active: color('red', '600'),
      $background-color-focus: color('red', '600'),
      $background-color-disabled: color('red', '300'),
      $border-color: transparent,
      $border-color-hover: transparent,
      $border-color-active: transparent,
      $border-color-focus: transparent,
      $border-color-disabled: transparent,
      $outline: true,
      $outline-color: transparent,
      $outline-color-hover: transparent,
      $outline-color-active: transparent,
      $outline-color-focus: transparent,
      $outline-color-disabled: transparent
    );

    letter-spacing: em(2.5px, 14px);
  }

  &--red-outline,
  &--danger-outline {
    @include button-theme(
      $color: color('red', '500'),
      $color-hover: color('red', '700'),
      $color-active: color('red', '900'),
      $color-focus: color('red', '900'),
      $color-disabled: color('red', '200'),
      $background-color: transparent,
      $background-color-hover: transparent,
      $background-color-active: transparent,
      $background-color-focus: transparent,
      $background-color-disabled: transparent,
      $border-color: color('red', '500'),
      $border-color-hover: color('red', '700'),
      $border-color-active: color('red', '900'),
      $border-color-focus: color('red', '900'),
      $border-color-disabled: color('red', '200'),
      $outline: true,
      $outline-color: transparent,
      $outline-color-hover: transparent,
      $outline-color-active: transparent,
      $outline-color-focus: transparent,
      $outline-color-disabled: transparent
    );

    letter-spacing: em(2.5px, 14px);
  }

  &--orange,
  &--warning {
    @include button-theme(
      $color: color('white'),
      $color-hover: color('white'),
      $color-active: color('white'),
      $color-focus: color('white'),
      $color-disabled: color('white'),
      $background-color: color('orange', '500'),
      $background-color-hover: color('orange', '600'),
      $background-color-active: color('orange', '600'),
      $background-color-focus: color('orange', '600'),
      $background-color-disabled: color('orange', '300'),
      $border-color: transparent,
      $border-color-hover: transparent,
      $border-color-active: transparent,
      $border-color-focus: transparent,
      $border-color-disabled: transparent,
      $outline: true,
      $outline-color: transparent,
      $outline-color-hover: transparent,
      $outline-color-active: transparent,
      $outline-color-focus: transparent,
      $outline-color-disabled: transparent
    );

    letter-spacing: em(2.5px, 14px);
  }

  &--orange-outline,
  &--danger-outline {
    @include button-theme(
      $color: color('orange', '500'),
      $color-hover: color('orange', '700'),
      $color-active: color('orange', '900'),
      $color-focus: color('orange', '900'),
      $color-disabled: color('orange', '200'),
      $background-color: transparent,
      $background-color-hover: transparent,
      $background-color-active: transparent,
      $background-color-focus: transparent,
      $background-color-disabled: transparent,
      $border-color: color('orange', '500'),
      $border-color-hover: color('orange', '700'),
      $border-color-active: color('orange', '900'),
      $border-color-focus: color('orange', '900'),
      $border-color-disabled: color('orange', '200'),
      $outline: true,
      $outline-color: transparent,
      $outline-color-hover: transparent,
      $outline-color-active: transparent,
      $outline-color-focus: transparent,
      $outline-color-disabled: transparent
    );

    letter-spacing: em(2.5px, 14px);
  }

  &--link {
    @include button-base(
      $line-height: 1.5,
      $transition: true,
      $outline: true,
      $border-style: solid,
      $transition-duration: 0.3s,
      $text-decoration: underline,
      $text-decoration-hover: underline
    );
    @include button-theme(
      $color: color('blue', '600'),
      $color-hover: color('blue', '600'),
      $color-active: color('blue', '600'),
      $color-focus: color('blue', '600'),
      $color-disabled: color('blue', '300'),
      $background-color: transparent,
      $background-color-hover: transparent,
      $background-color-active: transparent,
      $background-color-focus: transparent,
      $background-color-disabled: transparent,
      $border-color: transparent,
      $border-color-hover: transparent,
      $border-color-active: transparent,
      $border-color-focus: transparent,
      $border-color-disabled: transparent,
      $outline: true,
      $outline-color: transparent,
      $outline-color-hover: transparent,
      $outline-color-active: transparent,
      $outline-color-focus: transparent,
      $outline-color-disabled: transparent
    );
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--size {
    &-xsmall {
      @include button-size(
        $font-size: 12px,
        $font-weight: 400,
        $padding-vertical: 6px,
        $padding-horizontal: 16px,
        $border-radius: 48px,
        $border-width: 1px,
        $outline: true,
        $outline-width: 1px,
        $outline-offset: 0
      );
    }

    &-small {
      @include button-size(
        $font-size: 12px,
        $font-weight: 400,
        $padding-vertical: 8px,
        $padding-horizontal: 16px,
        $border-radius: 48px,
        $border-width: 1px,
        $outline: true,
        $outline-width: 1px,
        $outline-offset: 0
      );
    }

    &-large {
      @include button-size($font-size: 18px);
    }
  }
}
