//
// Text Style
// --------------------------------------------------

@import './variables';

.#{$text-style-class} {
  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------

  &--h1 {
    @include interpolate(font-size, rem(28px), rem(48px));

    display: block;
    margin: 0;
  }

  &--h2 {
    @include interpolate(font-size, rem(26px), rem(32px));

    display: block;
    margin: 0;
  }

  &--h3 {
    @include interpolate(font-size, rem(24px), rem(28px));

    display: block;
    margin: 0;
  }

  &--h4 {
    @include interpolate(font-size, rem(22px), rem(24px));

    display: block;
    margin: 0;
  }

  &--h5 {
    @include interpolate(font-size, rem(20px), rem(20px));

    display: block;
    margin: 0;
  }

  &--h6 {
    @include interpolate(font-size, rem(16px), rem(18px));

    display: block;
    margin: 0;
  }

  &--p {
    @include interpolate(font-size, rem(14px), rem(16px));

    display: block;
    margin: 0;
  }
}
