//
// Heading
// -------------------------

@import 'variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
}

@each $breakpoint, $heading in $heading-sizes {
  @if ($breakpoint == $heading-size) {
    @each $h, $size in $heading {
      #{$h} {
        font-size: rem($size);
      }
    }
  } @else {
    @include breakpoint($breakpoint up) {
      @each $h, $size in $heading {
        #{$h} {
          font-size: rem($size);
        }
      }
    }
  }
}
