//
// auto-complete-menu
// --------------------------------------------------

@import 'variables';

.#{$auto-complete-menu-class} {
  $this: &;

  background-color: color('white');
  list-style: none;
  padding: 0;
  margin: 0;

  //
  // Elements
  // -------------------------

  &__item {
    display: block;
    padding: rem(10px) rem(16px);

    &[aria-hidden] {
      cursor: pointer;
    }

    &:hover {
      background-color: color('light-gray', '200');
    }

    &.is-selected {
      background-color: color('light-gray', '400');
    }
  }

  //
  // Modifiers
  // -------------------------
}
