//
// Responsive Image
// --------------------------------------------------

@import 'variables';

.#{$responsive-image-class} {
  $this: &;

  position: relative;
  display: block;

  //
  // Elements
  // -------------------------

  &__item {
    display: block;
    width: 100%;
    transition-duration: $responsive-image-transition-duration;
    transition-property: opacity, visibility;
    opacity: 0;
    visibility: hidden;

    &:focus {
      outline: 0;
    }

    .is-loaded > &,
    &.is-loaded {
      visibility: visible;
      opacity: 1;
    }
  }

  &__placeholder {
    display: block;
    background-color: color('gray', '100');
  }

  //
  // Modifiers
  // -------------------------

  @each $classname, $ratios in $responsive-image-sizes {
    &--#{$classname} {
      #{$this}__placeholder,
      #{$this}__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &::before {
        $width: null;
        $height: null;

        @each $width, $height in $ratios {
          padding-bottom: calc((#{$height} / #{$width}) * 100%);
        }

        display: block;
        content: '';
      }
    }
  }

  &--contain {
    #{$this}__item {
      object-fit: contain;
    }
  }

  &--cover {
    #{$this}__item {
      object-fit: cover;
    }
  }
}
