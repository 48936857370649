// Outputs facing up media query for breakpoint.
//
// @param {String} $name
//
// @example scss - example
//   @include breakpoint-up(large)
// @example css - output
//   @media (min-width: 60em) { }
@mixin breakpoint-up($name) {
  $width: breakpoint($name);

  @media (min-width: #{rem($width)}) {
    @content;
  }
}
