//
// Anchor
// -------------------------

@import 'variables';

a {
  color: $anchor-color;
  text-decoration: $anchor-decoration;

  &:hover {
    color: $anchor-color-hover;
    text-decoration: $anchor-decoration-hover;
  }
}
