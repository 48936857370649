//
// filter bar
// --------------------------------------------------

@import 'variables';

.#{$filter-bar-class} {
  $this: &;

  position: sticky;
  top: 0;
  background-color: color('purple', '700');

  //
  // Elements
  // -------------------------

  &__list {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0 rem(54px);
    margin: 0;
    overflow-x: auto;
  }

  &__list-item {
    display: flex;
    flex-direction: column;
  }

  &__list-link {
    display: flex;
    align-items: center;
    font-size: rem(14px);
    padding: rem(18px) rem(12px);
    color: color('gray', '100');
    text-decoration: none;
    white-space: nowrap;
    flex-grow: 1;

    &:hover,
    &.is-active,
    &.active,
    .is-active > &,
    .active > & {
      color: color('blue', '500');
      text-decoration: none;
    }
  }

  &__dot {
    display: inline-block;
    font-size: rem(16px);
    margin-left: rem(4px);
    margin-right: rem(4px);
  }

  &__badge {
    position: relative;
    display: inline-block;
    padding: 0;
    font-size: rem(14px);
    transition-duration: $filter-bar-transition-duration;
    transition-property: margin-left;

    &::before {
      content: '';
      position: absolute;
      left: rem(-14px);
      right: rem(-14px);
      top: rem(-4px);
      bottom: rem(-4px);
      background-color: transparent;
      border-radius: rem(29px);
    }

    .is-active > &::before,
    .active > &::before {
      background-color: rgba(color('blue', '500'), 0.2);
    }

    .is-active > &,
    .active > & {
      margin-left: rem(14px);
    }
  }

  //
  // Modifiers
  // -------------------------
}
