//
// sidebar menu
// --------------------------------------------------

@import 'variables';

.#{$sidebar-menu-class} {
  $this: &;

  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;

  //
  // Elements
  // -------------------------

  &__divider {
    height: 1px;
    background-color: color('gray', '200');
    margin-top: rem(12px);
    margin-bottom: rem(12px);
  }

  &__item {
    &:not(:first-child):not(&--user) {
      margin-top: rem(12px);
    }

    &--action {
      padding: rem(12px) rem(24px);
    }

    &--user {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      margin-bottom: rem(24px);
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    padding: rem(4px) rem(12px) rem(4px) rem(24px);
    color: color('gray', '200');
    font-weight: 400;
    font-size: rem(16px);
    line-height: em(24px, 16px);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: rem(5px);
      height: 100%;
      background-color: color('light-gray', '100');
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      opacity: 0;
      visibility: hidden;
      transition-duration: $sidebar-transition-duration;
      transform: translateX(-100%);
    }

    &.active,
    &.is-active,
    &:hover {
      color: color('light-gray', '100');
      text-decoration: none;
    }

    &.active::before,
    &.is-active::before,
    &:hover::before {
      opacity: 1;
      visibility: visible;
      transform: translateX(0%);
    }
  }

  &__badge {
    position: relative;
    display: inline-block;
    padding: 0;
    font-size: rem(12px);
    background-color: rgba(color('light-gray', '800'), 0.2);
    border-radius: rem(29px);
    padding: rem(0px) rem(12px);
    margin-left: rem(10px);
    color: color('light-gray', '800');
  }

  &__link-icon {
    margin-right: rem(14px);
    font-size: rem(24px);
  }

  &__user-link {
    display: inline-flex;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__order-link {
    text-decoration: none;
    background-image: get($gradient-values, 'gradient-01');
    border-width: 0;

    &:hover {
      text-decoration: none;
    }
  }

  &__user-avatar {
    background-color: color('green', '600');
    color: color('white');
    transition-duration: $sidebar-transition-duration;
    transition-property: color, background-color;
    margin-right: rem(8px);

    &:hover {
      background-color: color('green', '700');
    }
  }

  &__seperator {
    flex-grow: 1;
  }

  //
  // Modifiers
  // -------------------------
}
