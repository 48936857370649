///
// Mixins
///

// @param {String|Array} $properties
// @param {Value} $min-value
// @param {Value} $max-value
// @param {Value} $min-screen
// @param {Value} $max-screen
//
// @example scss - base
//   @include interpolate(font-size, rem(16px), rem(32px))
// @example scss - array
//   @include interpolate((padding-top, padding-bottom), rem(16px), rem(32px))
// @example scss - optional media query
//   @include interpolate((padding-top, padding-bottom), 'sm', 'lg')
@mixin interpolate($properties, $min-value, $max-value, $min-screen: 'md', $max-screen: 'xxl') {
  $min-breakpoint: rem(get($breakpoint-sizes, $min-screen));
  $max-breakpoint: rem(get($breakpoint-sizes, $max-screen));

  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-breakpoint) {
    @each $property in $properties {
      #{$property}: calc-interpolation($min-breakpoint, $min-value, $max-breakpoint, $max-value);
    }
  }

  @media screen and (min-width: $max-breakpoint) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}
