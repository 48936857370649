//
// tag group
// --------------------------------------------------

@import 'variables';

.#{$product-option-select-class} {
  $this: &;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  //
  // Elements
  // -------------------------

  &__title {
    color: color('gray', '400');
    margin-right: em(22px);
    margin-bottom: em(22px);
  }

  &__radio {
    margin-bottom: em(22px);

    &:not(:last-child) {
      margin-right: em(22px);
    }
  }

  //
  // Modifiers
  // -------------------------
}