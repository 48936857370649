//
// Checkbox
// --------------------------------------------------

@import 'variables';

.#{$checkbox-class} {
  $this: &;

  display: inline-flex;
  position: relative;

  //
  // Elements
  // -------------------------

  &__label {
    display: inline-block;
    padding-left: rem($checkbox-size + $checkbox-label-offset);

    &::before {
      position: absolute;
      left: 0;
      top: ($checkbox-label-line-height - $checkbox-size) / 2;
      content: '';
      flex-shrink: 0;
      width: rem($checkbox-size);
      height: rem($checkbox-size);
      border-width: $checkbox-border-size;
      border-style: solid;
      border-color: $checkbox-border-color;
      background-color: $checkbox-background-color;
      background-repeat: no-repeat;
      background-size: rem($checkbox-icon-size);
      background-position: center;
      border-radius: rem($checkbox-border-radius);
      margin-right: rem($checkbox-label-offset);
      transition-duration: $checkbox-transition;
      transition-property: background-color, border-color;

      @if ($checkbox-outline == true) {
        outline-style: $checkbox-outline-style;
        outline-color: $checkbox-outline-color;
        outline-width: $checkbox-outline-width;
        outline-offset: $checkbox-outline-offset;
      }
    }
  }

  &__input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  // Hover
  // -------------------------
  #{$this}__label:hover::before {
    background-color: $checkbox-background-color-hover;
    border-color: $checkbox-border-color-hover;

    @if ($checkbox-outline == true) {
      outline-color: $checkbox-outline-color-hover;
    }
  }

  // Focus
  // -------------------------
  #{$this}__input:focus + #{$this}__label::before {
    background-color: $checkbox-background-color-focus;
    border-color: $checkbox-border-color-focus;

    @if ($checkbox-outline == true) {
      outline-color: $checkbox-outline-color-focus;
    }
  }

  // Indeterminate
  // -------------------------
  &.is-indeterminate > #{$this}__input + #{$this}__label::before,
  #{$this}__input.is-indeterminate + #{$this}__label::before {
    background-color: $checkbox-background-color-indeterminate;
    border-color: $checkbox-border-color-indeterminate;
    background-image: url($checkbox-indeterminate-icon-base64-image);

    @if ($checkbox-outline == true) {
      outline-color: $checkbox-outline-color-indeterminate;
    }
  }

  // Invalid
  // -------------------------
  &.is-invalid > #{$this}__input + #{$this}__label::before,
  #{$this}__input.is-invalid + #{$this}__label::before {
    background-color: $checkbox-background-color-invalid;
    border-color: $checkbox-border-color-invalid;

    @if ($checkbox-outline == true) {
      outline-color: $checkbox-outline-color-invalid;
    }
  }

  // Checked
  // -------------------------
  #{$this}__input:checked + #{$this}__label::before {
    background-color: $checkbox-background-color-checked;
    border-color: $checkbox-border-color-checked;
    background-image: url($checkbox-checked-icon-base64-image);

    @if ($checkbox-outline == true) {
      outline-color: $checkbox-outline-color-checked;
    }
  }

  // Valid
  // -------------------------
  &.is-valid > #{$this}__input:checked + #{$this}__label::before,
  #{$this}__input.is-valid:checked + #{$this}__label::before {
    background-color: $checkbox-background-color-valid;
    border-color: $checkbox-border-color-valid;

    @if ($checkbox-outline == true) {
      outline-color: $checkbox-outline-color-valid;
    }
  }

  // Disabled
  // -------------------------
  #{$this}__input:disabled + #{$this}__label {
    cursor: no-drop;
  }

  #{$this}__input:disabled + #{$this}__label::before {
    opacity: $checkbox-disabled-opacity;
  }

  //
  // Modifiers
  // -------------------------

  &--option-type-button {
    #{$this}__label {
      white-space: nowrap;
      padding: em($checkbox-button-padding-y, $checkbox-button-font-size)
        em($checkbox-button-padding-x, $checkbox-button-font-size);
      line-height: em($checkbox-button-line-height, $checkbox-button-font-size);
      border-width: $checkbox-button-border-size;
      border-style: solid;
      background-color: color('light-gray', '300');
      border-color: color('light-gray', '600');
      color: color('light-gray', '700');
      border-radius: em($checkbox-button-border-radius, $checkbox-button-font-size);

      &::before {
        display: none;
      }
    }

    #{$this}__input:checked + #{$this}__label {
      background-color: color('blue', '500');
      border-color: color('blue', '500');
      color: color('light-gray', '100');
    }
  }

  &--button-shape-circle {
    #{$this}__label {
      border-radius: em($checkbox-button-border-radius-circle, $checkbox-button-font-size);
    }
  }
}
