//
// Divider
// --------------------------------------------------

@import 'variables';

.#{$divider-class} {
  $this: &;

  display: flex;
  align-items: center;
  margin-top: rem(16px);
  margin-bottom: rem(16px);
  color: color('gray', '500');

  &::before {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: color('gray', '200');
  }

  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: color('gray', '200');
  }

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------

  &--with-text {
    &::before {
      margin-right: rem(12px);
    }

    &::after {
      margin-left: rem(12px);
    }
  }

  &--position-left {
    &::before {
      content: '';
      flex-grow: 0;
      flex-basis: rem(16px);
    }
  }

  &--position-right {
    &::after {
      content: '';
      flex-grow: 0;
      flex-basis: rem(16px);
    }
  }
}
