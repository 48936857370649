///
// Functions
///

// @param {Value} $min-screen
// @param {Value} $min-value
// @param {Value} $max-screen
// @param {Value} $max-value
//
// @example scss - base
//   @include calc-interpolation(768px, 16px, 1440px, 32px)

@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen;

  $sign: '+';
  @if ($b < 0) {
    $sign: '-';
    $b: abs($b);
  }
  @return calc(#{$a * 100}vw #{$sign} #{$b});
}
