//
// empty state
// --------------------------------------------------

@import 'variables';

.#{$empty-state-class} {
  $this: &;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  //
  // Elements
  // -------------------------

  &__image {
    max-width: 60%;
  }

  &__title {
    margin-top: rem(48px);
    color: color('purple', '800');
  }

  &__text {
    margin-top: rem(22px);
    color: color('gray', '400');
  }

  //
  // Modifiers
  // -------------------------
}
