//
// order table
// --------------------------------------------------

@import 'variables';

.#{$order-table-class} {
  $this: &;

  font-size: rem(14px);
  width: 100%;
  min-width: rem(1000px);

  thead {
    position: sticky;
    top: rem(60px);
    background-color: color('white');
  }

  thead th {
    // font-size: rem(16px);
    text-align: left;
  }

  tbody td {
    padding: em(6px) em(15px);
  }

  tbody tr {
    background-color: color('white');

    &:hover {
      background-color: color('light-gray', '300');
    }

    &.is-danger {
      background-color: color('red', '100');

      &:hover {
        background-color: color('red', '200');
      }
    }

    &.is-warning {
      background-color: color('yellow', '100');

      &:hover {
        background-color: color('yellow', '200');
      }
    }
  }

  td[role='gridcell'] {
    cursor: pointer;
  }

  //
  // Modifiers
  // -------------------------

  &--striped {
    tbody tr:nth-child(even) {
      background-color: color('light-gray', '300');
    }
  }
}
