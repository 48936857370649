//
// VARIABLES
// --------------------------------------------------

$checkbox-class: 'checkbox';

$checkbox-size: 20px;
$checkbox-label-offset: 8px;
$checkbox-label-line-height: 24px;
$checkbox-transition: 0.3s;
$checkbox-border-radius: 0;
$checkbox-border-size: 1px;
$checkbox-outline: false;
$checkbox-outline-width: 0;
$checkbox-outline-offset: 0;
$checkbox-outline-style: solid;
$checkbox-disabled-opacity: 0.5;
$checkbox-icon-size: $checkbox-size - ($checkbox-border-size * 2);
$checkbox-checked-icon-base64-image: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTguNzEgNy4yMWExIDEgMCAwIDAtMS40MiAwbC03LjQ1IDcuNDYtMy4xMy0zLjE0QTEuMDIyIDEuMDIyIDAgMCAwIDUuMjkgMTNsMy44NCAzLjg0YTEgMSAwIDAgMCAxLjQyIDBsOC4xNi04LjE2YTEgMSAwIDAgMCAwLTEuNDdaIiBmaWxsPSIjZmZmIi8+PC9zdmc+';
$checkbox-indeterminate-icon-base64-image: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5IDExSDVDNC43MzQ3OCAxMSA0LjQ4MDQzIDExLjEwNTQgNC4yOTI4OSAxMS4yOTI5QzQuMTA1MzYgMTEuNDgwNCA0IDExLjczNDggNCAxMkM0IDEyLjI2NTIgNC4xMDUzNiAxMi41MTk2IDQuMjkyODkgMTIuNzA3MUM0LjQ4MDQzIDEyLjg5NDYgNC43MzQ3OCAxMyA1IDEzSDE5QzE5LjI2NTIgMTMgMTkuNTE5NiAxMi44OTQ2IDE5LjcwNzEgMTIuNzA3MUMxOS44OTQ2IDEyLjUxOTYgMjAgMTIuMjY1MiAyMCAxMkMyMCAxMS43MzQ4IDE5Ljg5NDYgMTEuNDgwNCAxOS43MDcxIDExLjI5MjlDMTkuNTE5NiAxMS4xMDU0IDE5LjI2NTIgMTEgMTkgMTFaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4=';

$checkbox-button-font-size: 16px;
$checkbox-button-line-height: 22px;
$checkbox-button-border-size: 1px;
$checkbox-button-padding-x: 18px;
$checkbox-button-padding-y: 6px;
$checkbox-button-border-radius-circle: $checkbox-button-font-size + $checkbox-button-line-height +
  ($checkbox-button-padding-y * 2) + ($checkbox-button-border-size * 2);
$checkbox-button-border-radius: 2px;

// Default
$checkbox-background-color: transparent;
$checkbox-border-color: currentColor;
$checkbox-outline-color: transparent;

// Hover
$checkbox-background-color-hover: transparent;
$checkbox-border-color-hover: currentColor;
$checkbox-outline-color-hover: transparent;

// Focus
$checkbox-background-color-focus: transparent;
$checkbox-border-color-focus: currentColor;
$checkbox-outline-color-focus: transparent;

// Checked
$checkbox-background-color-checked: color('gray', '800');
$checkbox-border-color-checked: color('gray', '800');
$checkbox-outline-color-checked: transparent;

// Indeterminate
$checkbox-background-color-indeterminate: color('white');
$checkbox-border-color-indeterminate: color('gray', '800');
$checkbox-outline-color-indeterminate: transparent;

// Valid
$checkbox-background-color-valid: color('black');
$checkbox-border-color-valid: color('black');
$checkbox-outline-color-valid: transparent;

// Invalid
$checkbox-background-color-invalid: color('white');
$checkbox-border-color-invalid: color('red', '700');
$checkbox-outline-color-invalid: transparent;
