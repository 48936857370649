//
// auto complete overlay
// --------------------------------------------------

@import 'variables';

.#{$auto-complete-overlay-class} {
  $this: &;

  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 0;
  top: calc(100% - 2px);
  width: 100%;
  max-height: rem(300px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border: $auto-complete-border-width solid $auto-complete-border-color;
  border-top: 0;
  transition-duration: $auto-complete-transition-duration;
  transition-property: border-color, opacity, visibility;

  .is-focus > & {
    opacity: 1;
    visibility: visible;
  }

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------
}
