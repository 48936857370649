//
// Icon Class
// --------------------------------------------------

@import 'variables';

.#{$icon-class} {
  $this: &;

  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------
}
