//
// avatar
// --------------------------------------------------

@import 'variables';

.#{$avatar-class} {
  $this: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: rem(36px);
  width: em(36px, 36px);
  height: em(36px, 36px);
  overflow: hidden;
  color: color('gray', '700');
  background-color: color('light-gray', '400');

  //
  // Elements
  // -------------------------

  &__image {
    display: block;
    width: em(36px, 36px);
    height: em(36px, 36px);
  }

  &__text {
    font-size: em(14px, 36px);
  }

  &__icon,
  svg {
    width: 1em;
    height: 1em;
    font-size: em(16px, 36px);
  }

  //
  // Modifiers
  // -------------------------

  &--shape {
    &-circle {
      border-radius: 50%;
    }
  }
}
