//
// Select Size
//
// @param $height
// @param $padding-vertical
// @param $padding-horizontal
// @param $border-radius
// @param $border-width
// @param $font-size
// @param $font-weight
// @param $line-height
// -------------------------
@mixin select-size(
  $height: null,
  $padding-vertical: 8px,
  $padding-horizontal: 16px,
  $border-radius: null,
  $border-width: null,
  $outline: true,
  $outline-width: null,
  $outline-offset: null,
  $font-size: 16px,
  $font-weight: null,
  $arrow-gap: 16px,
  $arrow-size: 16px,
  $arrow-base64-image: null
) {
  @if $height == auto {
    height: auto;
  }

  @if $height != auto {
    height: rem($height);
  }

  padding: em($padding-vertical, $font-size) em($padding-horizontal, $font-size);
  border-radius: $border-radius;
  border-width: $border-width;
  font-size: rem($font-size);
  font-weight: $font-weight;
  padding-right: (em($arrow-gap, $font-size) * 2) + em($arrow-size, $font-size);

  @if $arrow-base64-image {
    background-image: url($arrow-base64-image);
    background-position: right em($arrow-gap, $font-size) center;
    background-size: em($arrow-size, $font-size) em($arrow-size, $font-size);
  }

  @if ($outline == true) {
    outline-width: $outline-width;
    outline-offset: $outline-offset;
  }
}
