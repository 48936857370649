//
// product drawer
// --------------------------------------------------

@import 'variables';

.#{$product-drawer-class} {
  $this: &;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  //
  // Elements
  // -------------------------

  &__header {
    padding: rem(20px) rem(40px);
    border-bottom: 1px solid color('light-gray', '300');
  }

  &__body {
    flex-grow: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__footer {
    padding: rem(20px) rem(40px);
    border-top: 1px solid color('light-gray', '300');
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__submit-button {
    margin-left: auto;
  }

  &__submit-buttons {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    gap: rem(18px);
  }

  &__section {
    padding: rem(20px) rem(40px);

    &:not(:last-child) {
      border-bottom: 1px solid color('light-gray', '300');
    }
  }

  &__section-title {
    font-size: rem(20px);
    font-weight: 400;
    color: color('gray', '900');
    margin-top: rem(36px);
    margin-bottom: rem(24px);
  }

  &__id {
    font-size: rem(28px);
    line-height: 1;
  }

  &__header-title {
    display: block;
    font-size: rem(20px);
    color: color('gray', '400');
    text-align: center;
  }

  &__order-type {
    &:not(:last-child) {
      margin-right: rem(28px);
    }
  }

  &__prop {
    &:not(:last-child) {
      margin-right: rem(38px);
    }

    &-label {
      color: color('gray', '400');
    }

    &-value {
      margin-left: rem(12px);
      color: color('purple', '700');
    }
  }

  &__order-label {
    display: block;
    color: color('gray', '400');
  }

  &__order-value {
    font-size: rem(20px);
    display: block;
    color: color('gray', '900');
    margin-top: rem(4px);
  }

  &__order-date {
    text-align: right;
  }

  &__order-date-icon {
    color: color('gray', '400');
    margin-right: rem(10px);
  }

  &__tag {
    cursor: pointer;
  }

  //
  // Modifiers
  // -------------------------
}
