//
// Radio
// --------------------------------------------------

@import 'variables';

.#{$radio-class} {
  $this: &;

  display: inline-flex;
  position: relative;

  //
  // Elements
  // -------------------------

  &__label {
    position: relative;
    padding-left: rem($radio-size + $radio-label-offset);
    flex-shrink: 0;

    &::before {
      position: absolute;
      left: 0;
      top: ($radio-label-line-height - $radio-size) / 2;
      content: '';
      width: rem($radio-size);
      height: rem($radio-size);
      border-width: $radio-border-size;
      border-style: solid;
      border-color: $radio-border-color;
      background-color: $radio-background-color;
      background-repeat: no-repeat;
      background-size: rem($radio-icon-size);
      background-position: center;
      border-radius: rem($radio-border-radius);
      margin-right: rem($radio-label-offset);
      transition-duration: $radio-transition;
      transition-property: background-color, border-color;

      @if ($radio-outline == true) {
        outline-style: $radio-outline-style;
        outline-color: $radio-outline-color;
        outline-width: $radio-outline-width;
        outline-offset: $radio-outline-offset;
      }
    }
  }

  &__input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  // Hover
  // -------------------------
  #{$this}__label:hover::before {
    background-color: $radio-background-color-hover;
    border-color: $radio-border-color-hover;

    @if ($radio-outline == true) {
      outline-color: $radio-outline-color-hover;
    }
  }

  // Focus
  // -------------------------
  #{$this}__input:focus + #{$this}__label::before {
    background-color: $radio-background-color-focus;
    border-color: $radio-border-color-focus;

    @if ($radio-outline == true) {
      outline-color: $radio-outline-color-focus;
    }
  }

  // Invalid
  // -------------------------
  &.is-invalid > #{$this}__input + #{$this}__label::before,
  #{$this}__input.is-invalid + #{$this}__label::before {
    background-color: $radio-background-color-invalid;
    border-color: $radio-border-color-invalid;

    @if ($radio-outline == true) {
      outline-color: $radio-outline-color-invalid;
    }
  }

  // Checked
  // -------------------------
  #{$this}__input:checked + #{$this}__label::before {
    background-color: $radio-background-color-checked;
    border-color: $radio-border-color-checked;
    background-image: url($radio-checked-icon-base64-image);

    @if ($radio-outline == true) {
      outline-color: $radio-outline-color-checked;
    }
  }

  // Valid
  // -------------------------
  &.is-valid > #{$this}__input:checked + #{$this}__label::before,
  #{$this}__input.is-valid:checked + #{$this}__label::before {
    background-color: $radio-background-color-valid;
    border-color: $radio-border-color-valid;

    @if ($radio-outline == true) {
      outline-color: $radio-outline-color-valid;
    }
  }

  // Disabled
  // -------------------------
  #{$this}__input:disabled + #{$this}__label {
    cursor: no-drop;
  }

  #{$this}__input:disabled + #{$this}__label::before {
    opacity: $radio-disabled-opacity;
  }

  //
  // Modifiers
  // -------------------------

  &--option-type-button {
    #{$this}__label {
      white-space: nowrap;
      padding: em($radio-button-padding-y, $radio-button-font-size)
        em($radio-button-padding-x, $radio-button-font-size);
      line-height: em($radio-button-line-height, $radio-button-font-size);
      border-width: $radio-button-border-size;
      border-style: solid;
      background-color: color('light-gray', '300');
      border-color: color('light-gray', '600');
      color: color('light-gray', '700');
      border-radius: em($radio-button-border-radius, $radio-button-font-size);

      &::before {
        display: none;
      }
    }
    
    #{$this}__input:enabled + #{$this}__label {
      background-color: color('light-gray', '300');
      border-color: color('blue', '500');
      color: color('blue', '500');
    }

    #{$this}__input:checked + #{$this}__label {
      background-color: color('blue', '500');
      border-color: color('blue', '500');
      color: color('light-gray', '100');
    }

  }

  &--button-shape-circle {
    #{$this}__label {
      border-radius: em($radio-button-border-radius-circle, $radio-button-font-size);
    }
  }
}
