//
// Fonts
// --------------------------------------------------

@import 'include/font-face';
@import 'variables';

//
// Font Weights
// -------------------------
// 100 = thin
// 200 = extra-light
// 300 = light
// 400 = normal, book
// 500 = medium
// 600 = semibold, demibold
// 700 = bold
// 800 = heavy
// 900 = black

//
//  Mixin Usage
//  @include font-face($fontName, $fontFolder, $fontFileName, $fontWeight: normal, $fontStyle: normal);
//

@include font-face('GT America', 'GTAmerica', 'GTAmerica-Regular', 400, normal);
