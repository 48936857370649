//
// Form theme variables for Input, Select, Textarea
//
// @param $color
// @param $color-focus
// @param $color-valid
// @param $color-invalid
// @param $color-disabled
// @param $color-placeholder
// @param $background-color
// @param $background-color-focus
// @param $background-color-valid
// @param $background-color-invalid
// @param $background-color-disabled
// @param $border-color
// @param $border-color-focus
// @param $border-color-valid
// @param $border-color-invalid
// @param $border-color-disabled
// @param $outline: true
// @param $outline-color
// @param $outline-color-focus
// -------------------------
@mixin textarea-theme(
  $color: null,
  $color-focus: null,
  $color-valid: null,
  $color-invalid: null,
  $color-disabled: null,
  $color-placeholder: null,
  $background-color: null,
  $background-color-focus: null,
  $background-color-valid: null,
  $background-color-invalid: null,
  $background-color-disabled: null,
  $border-color: null,
  $border-color-focus: null,
  $border-color-valid: null,
  $border-color-invalid: null,
  $border-color-disabled: null,
  $outline: false,
  $outline-color: null,
  $outline-color-focus: null
) {
  background-color: $background-color;
  border-color: $border-color;
  color: $color;

  @if ($outline == true) {
    outline-color: $outline-color;
  }

  &::placeholder {
    color: $color-placeholder;
  }

  &:focus {
    background-color: $background-color-focus;
    border-color: $border-color-focus;
    color: $color-focus;

    @if ($outline == true) {
      outline-color: $outline-color-focus;
    }
  }

  &.is-valid,
  .is-valid > & {
    background-color: $background-color-valid;
    border-color: $border-color-valid;
    color: $color-valid;
  }

  &.is-invalid,
  .is-invalid > & {
    background-color: $background-color-invalid;
    border-color: $border-color-invalid;
    color: $color-invalid;
  }

  &[disabled],
  &.is-disabled,
  .is-disabled > & {
    background-color: $background-color-disabled;
    border-color: $border-color-disabled;
    color: $color-disabled;

    &:focus {
      background-color: $background-color-disabled;
      border-color: $border-color-disabled;
      color: $color-disabled;
    }
  }
}
