//
// Button Theme
//
// @param {String} $color
// @param {String} $color-hover
// @param {String} $color-active
// @param {String} $color-focus
// @param {String} $color-disabled
// @param {String} $background-color
// @param {String} $background-color-hover
// @param {String} $background-color-active
// @param {String} $background-color-focus
// @param {String} $background-color-disabled
// @param {String} $border-color
// @param {String} $border-color-hover
// @param {String} $border-color-active
// @param {String} $border-color-focus
// @param {String} $border-color-disabled
// @param {Boolean} $outline
// @param {String} $outline-color
// @param {String} $outline-color-hover
// @param {String} $outline-color-active
// @param {String} $outline-color-focus
// @param {String} $outline-color-disabled
// @param {Boolean} $loading-icon
// -------------------------
@mixin button-theme(
  $color: null,
  $color-hover: null,
  $color-active: null,
  $color-focus: null,
  $color-disabled: null,
  $background-color: null,
  $background-color-hover: null,
  $background-color-active: null,
  $background-color-focus: null,
  $background-color-disabled: null,
  $border-color: null,
  $border-color-hover: null,
  $border-color-active: null,
  $border-color-focus: null,
  $border-color-disabled: null,
  $outline: false,
  $outline-color: null,
  $outline-color-hover: null,
  $outline-color-active: null,
  $outline-color-focus: null,
  $outline-color-disabled: null,
  $loading-icon: true
) {
  background-color: $background-color;
  border-color: $border-color;
  color: $color;

  @if ($outline == true) {
    outline-color: $outline-color;
  }

  &:hover,
  &.is-hover {
    background-color: $background-color-hover;
    border-color: $border-color-hover;
    color: $color-hover;

    @if ($outline == true) {
      outline-color: $outline-color-hover;
    }
  }

  &:focus,
  &.is-focus {
    background-color: $background-color-focus;
    border-color: $border-color-focus;
    color: $color-focus;

    @if ($outline == true) {
      outline-color: $outline-color-focus;
    }
  }

  &:active,
  &.is-pressed {
    background-color: $background-color-active;
    border-color: $border-color-active;
    color: $color-active;

    @if ($outline == true) {
      outline-color: $outline-color-active;
    }
  }

  &:disabled,
  &[disabled],
  &.is-disabled {
    background-color: $background-color-disabled;
    border-color: $border-color-disabled;
    color: $color-disabled;
    user-select: none;
    pointer-events: none;

    @if ($outline == true) {
      outline-color: $outline-color-disabled;
    }
  }

  @if ($loading-icon == true) {
    &.is-loading {
      position: relative;
      transition: none;
      user-select: none;
      pointer-events: none;

      @if $color {
        color: rgba($color, 0);
      }

      &::after {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -0.5em;
        margin-left: -0.5em;
        display: inline-block;
        width: 1em;
        height: 1em;
        border: 1px solid transparent;
        animation: loader 0.6s linear infinite;
        border-bottom-color: $color;
        border-left-color: $color;
        border-radius: 50%;
        border-top-color: $color;
        content: '';
        font-size: 1em;
        vertical-align: middle;
      }
    }
  }
}
