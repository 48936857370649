//
// grid
// --------------------------------------------------

@import 'variables';

.#{$grid-class} {
  $this: &;

  display: grid;
  grid-template-rows: repeat(var(--grid-rows, 1), 1fr);
  grid-template-columns: repeat(var(--grid-columns, #{$grid-columns}), 1fr);
  grid-column-gap: var(--grid-gap, #{$grid-gutter-width});

  @include make-cssgrid();

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------

  &--gap-false {
    --grid-gap: 0;
  }
}
