//
// login
// --------------------------------------------------

@import 'variables';

.#{$login-class} {
  $this: &;

  //
  // Elements
  // -------------------------

  &__container {
    padding-top: rem(64px);
    padding-bottom: rem(64px);
  }

  &__form {
    max-width: rem(500px);
    margin-left: auto;
    margin-right: auto;
  }

  //
  // Modifiers
  // -------------------------
}
