//
// users grid
// --------------------------------------------------

@import 'variables';

.#{$users-grid-class} {
  $this: &;

  //
  // Elements
  // -------------------------

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      box-shadow: 0 -1px 0 0 rgba(color('black'), 0.1);
    }

    &:nth-child(odd) {
      background-color: color('light-gray', '300');
    }
  }

  &__user-name {
    padding: em(13px) em(15px);
  }

  &__user-actions {
    display: flex;
    align-items: center;
    gap: rem(8px);
    margin-left: auto;
    padding: em(13px) em(15px);
  }

  //
  // Modifiers
  // -------------------------
}
