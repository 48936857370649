//
// Form Select
//
// Markup:
// <select class="select">
//   <option>Option</option>
// </select>
//
// --------------------------------------------------

@import 'mixins/select-base';
@import 'mixins/select-theme';
@import 'mixins/select-size';
@import 'variables';

.#{$select-class} {
  @include select-base(
    $transition: true,
    $outline: false,
    $border-style: solid,
    $transition-duration: 0.3s,
    $appearance: none
  );
  @include select-theme(
    $color: color('gray', '800'),
    $color-focus: color('gray', '800'),
    $color-valid: color('gray', '800'),
    $color-invalid: color('gray', '800'),
    $color-disabled: color('gray', '400'),
    $color-placeholder: color('gray', '400'),
    $background-color: transparent,
    $background-color-focus: transparent,
    $background-color-valid: color('green', '100'),
    $background-color-invalid: color('red', '100'),
    $background-color-disabled: color('light-gray', '300'),
    $border-color: color('light-gray', '600'),
    $border-color-focus: color('gray', '300'),
    $border-color-valid: color('green', '700'),
    $border-color-invalid: color('red', '700'),
    $border-color-disabled: color('gray', '100'),
    $outline: false,
    $outline-color: transparent,
    $outline-color-focus: color('blue', '100')
  );

  @include select-size(
    $height: 46px,
    $font-size: 16px,
    $font-weight: 400,
    $padding-vertical: 10px,
    $padding-horizontal: 16px,
    $border-radius: 2px,
    $border-width: 1px,
    $outline: true,
    $outline-width: 1px,
    $outline-offset: 0,
    $arrow-gap: 16px,
    $arrow-size: 24px,
    $arrow-base64-image: $select-arrow-base64-image
  );

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------
}
