//
// modal dialog
// --------------------------------------------------

@import 'variables';

.#{$modal-dialog-class} {
  $this: &;

  //
  // Elements
  // -------------------------

  &__content {
    padding: rem(45px);
    border-top-width: 10px;
    border-top-style: solid;
    border-top-color: transparent;
    text-align: center;
  }

  &__icon {
    font-size: rem(30px);
    color: rgba(color('gray', '200'), 0.2);
  }

  &__title {
    font-size: rem(24px);
    color: color('gray', '900');
    margin-top: rem(18px);
    margin-bottom: rem(12px);
  }

  &__text {
    color: color('gray', '300');
    font-size: rem(16px);
    margin-top: rem(12px);
    margin-bottom: 0;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid color('light-gray', '300');
    padding: rem(16px);
    gap: rem(20px);
  }

  //
  // Modifiers
  // -------------------------

  &--theme {
    &-success #{$this}__content {
      border-top-color: color('green', '600');
    }

    &-warning #{$this}__content {
      border-top-color: color('yellow', '600');
    }

    &-info #{$this}__content {
      border-top-color: color('blue', '600');
    }

    &-danger #{$this}__content {
      border-top-color: color('red', '600');
    }
  }
}
