//
// analytics table
// --------------------------------------------------

@import 'variables';

.#{$analytics-table-class} {
  $this: &;

  font-size: rem(14px);
  width: 100%;
  color: color('white');
  background-color: color('purple', '800');
  border-right: 1px solid color('gray', '500');
  border-bottom: 1px solid color('gray', '500');

  //
  // Elements
  // -------------------------

  thead th {
    padding: em(6px) em(15px);
    color: color('white');
    background-color: color('purple', '800');
    text-align: center;
  }

  tbody td {
    padding: em(6px) em(15px);
    color: color('gray', '100');
    text-align: center;
    border-top: 1px solid color('gray', '500');
    border-left: 1px solid color('gray', '500');
  }

  thead tr#{$this}__product-props {
    position: sticky;
    top: 0;
  }

  thead th#{$this}__in-stock,
  thead th#{$this}__in-production,
  thead th#{$this}__total {
    border-top: 1px solid color('gray', '500');
    border-left: 1px solid color('gray', '500');
  }

  thead th#{$this}__total {
    color: color('green', '600');
  }

  // thead th#{$this}__product-name,
  thead th#{$this}__product-size,
  thead th#{$this}__product-finish,
  thead th#{$this}__product-boxType,
  thead th#{$this}__product-logos,
  thead th#{$this}__product-category-a,
  thead th#{$this}__product-category-b,
  thead th#{$this}__product-customer,
  thead th#{$this}__product-stock,
  thead th#{$this}__product-stock-production {
    border-top: 1px solid color('gray', '500');
    border-left: 1px solid color('gray', '500');
  }

  thead th#{$this}__product-stock-production {
    color: color('green', '600');
  }

  tbody td#{$this}__product-stock-production {
    color: color('green', '600');
    background-color: color('purple', '700');
  }

  //
  // Modifiers
  // -------------------------
}