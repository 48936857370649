//
// notifications
// --------------------------------------------------

@import 'variables';

.#{$notifications-class} {
  $this: &;

  //
  // Elements
  // -------------------------

  &__content {
    padding: rem(32px) rem(48px);
    max-width: rem(900px);
  }

  &__section {
    padding-bottom: rem(30px);
    margin-bottom: rem(30px);
    border-bottom: 1px solid color('light-gray', '400');
  }

  &__section-title {
    font-size: rem(20px);
    font-weight: 400;
    color: color('gray', '900');
    margin-top: 0;
    margin-bottom: rem(30px);
  }

  &__search {
    width: rem(515px);
    max-width: 100%;
  }

  &__pagination {
    margin-top: rem(32px);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: rem(8px);
    margin-top: rem(30px);
  }

  //
  // Modifiers
  // -------------------------
}
