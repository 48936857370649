//
// Table
// --------------------------------------------------

@import 'variables';

table {
  border-collapse: collapse;
  border-spacing: 0;
  color: color('gray', '400');

  tbody tr {
    box-shadow: 0 -1px 0 0 rgba(color('black'), 0.1);
  }

  thead th {
    font-weight: 400;
    padding: em(15px);
    color: color('gray', '800');
  }

  tbody td {
    font-weight: 400;
    padding: em(13px) em(15px);
  }
}
