///
// Functions
///

// Converts pixel value into matching em values.
//
// @return {Value}
//
// @example scss - example
//   em(16px, 16px);
// @example css - output
//   1em
@function em($pixels, $context: 16px) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @if (unit($pixels) != 'px') {
    @error 'Error while converting to EM unit. Value [#{$pixels}] must be in pixels';
  }

  @return ($pixels / $context) * 1em;
}
