//
// Button Sizes
//
// @param {String} $font-size
// @param {Number} $font-weight
// @param {String} $padding-vertical
// @param {String} $padding-horizontal
// @param {String} $border-radius
// @param {String} $border-width
// @param {Boolean} $outline
// @param {String} $outline-width
// @param {String} $outline-offset
// -------------------------
@mixin button-size(
  $font-size: null,
  $font-weight: null,
  $padding-vertical: null,
  $padding-horizontal: null,
  $border-radius: null,
  $border-width: null,
  $outline: false,
  $outline-width: null,
  $outline-offset: null
) {
  @if ($border-width) {
    border-width: $border-width;
  }

  @if ($outline == true) {
    outline-width: $outline-width;
    outline-offset: $outline-offset;
  }

  @if ($padding-vertical) {
    @if ($padding-horizontal) {
      padding: em($padding-vertical, $font-size) em($padding-horizontal, $font-size);
    } @else {
      padding-top: em($padding-vertical, $font-size);
      padding-bottom: em($padding-vertical, $font-size);
    }
  } @else {
    @if ($font-size) {
      @if ($padding-horizontal) {
        padding-right: em($padding-horizontal, $font-size);
        padding-left: em($padding-horizontal, $font-size);
      }
    }
  }

  @if ($border-radius) {
    border-radius: $border-radius;
  }

  @if ($font-size) {
    font-size: rem($font-size);
  }

  @if ($font-weight) {
    font-weight: $font-weight;
  }
}
