//
// VARIABLES
// --------------------------------------------------

//
// COLORS
// -------------------------
$color-values: (
  'white': #fff,
  'black': #000,
  'gray': (
    '100': #9d9d9d,
    '200': #8c8c8c,
    '300': #7a7a7a,
    '400': #696969,
    '500': #585858,
    '600': #464646,
    '700': #353535,
    '800': #232323,
    '900': #121212,
  ),
  'light-gray': (
    '100': #fefefe,
    '200': #fafafa,
    '300': #f6f6f6,
    '400': #ededed,
    '500': #e6e6e6,
    '600': #dbdbdb,
    '700': #cccccc,
    '800': #bfbfbf,
  ),
  'purple': (
    '100': #f9f9fb,
    '200': #cfd1de,
    '300': #a4a9c1,
    '400': #7a81a3,
    '500': #575e7f,
    '600': #3a3e54,
    '700': #2c2d3f,
    '800': #1d1f2a,
  ),
  'blue': (
    '100': #e9f3fb,
    '200': #d4e8f7,
    '300': #a9d2ef,
    '400': #7ebbe7,
    '500': #53a5df,
    '600': #288ed7,
    '700': #2072ac,
    '800': #185581,
    '900': #0f2057,
  ),
  'green': (
    '100': #edf7f4,
    '200': #dbefe8,
    '300': #b8e0d2,
    '400': #95d0bc,
    '500': #72c0a6,
    '600': #4eb18f,
    '700': #3e8e72,
    '800': #2f6a56,
    '900': #1f4739,
  ),
  'red': (
    '100': #fce9e9,
    '200': #f8d3d3,
    '300': #f2a6a6,
    '400': #e86464,
    '500': #c71e1e,
    '600': #9b1717,
    '700': #851414,
    '800': #6f1111,
    '900': #580e0e,
  ),
  'orange': (
    '100': #fee3d0,
    '200': #fcc399,
    '300': #faa669,
    '400': #f98d3f,
    '500': #f87415,
    '600': #e16207,
    '700': #c25406,
    '800': #a84905,
    '900': #994205,
  ),
  'yellow': (
    '100': #fdf6e7,
    '200': #fbecd0,
    '300': #f8d9a0,
    '400': #f4c671,
    '500': #f1b341,
    '600': #eda112,
    '700': #be800e,
    '800': #8e600b,
    '900': #5f4007,
  ),
);

//
// GRADIENTS
// -------------------------

$gradient-values: (
  'gradient-01': linear-gradient(90deg, color('green', '600'), color('blue', '600')),
);

//
// Global
// -------------------------

//
// Core
// -------------------------
$base-font-size-root: 16px;
$base-font-size-body: 16px;
$base-font-line-height: 1.5;
$base-font-antialiasing: true;
$base-font-family-primary: 'GT America', sans-serif;
$code-font-family-monospace: 'Lucida Console', 'Monaco', monospace;
$base-body-color: color('black');
$base-body-background-color: color('white');

$heading-size: default;
$heading-font-family: inherit;
$heading-color: color('black');
$heading-line-height: 1.25;
$heading-font-weight: 400;
$heading-sizes: (
  'default': (
    'h1': 28px,
    'h2': 26px,
    'h3': 24px,
    'h4': 22px,
    'h5': 20px,
    'h6': 16px,
  ),
  'md': (
    'h1': 48px,
    'h2': 32px,
    'h3': 28px,
    'h4': 24px,
    'h5': 20px,
    'h6': 18px,
  ),
);

$anchor-color: color('blue', '600');
$anchor-color-hover: color('blue', '600');
$anchor-decoration: none;
$anchor-decoration-hover: underline;

//
// Breakpoint
// -------------------------
$breakpoint-sizes: (
  'xs': 320px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1440px,
);

//
// Variables
// -------------------------
$g-transition-duration: 0.3s;

//
// Components
// -------------------------
$g-sidebar-width: 258px;
