//
// Textarea Base
//
// @param $display: block
// @param $width: 100%
// @param $font-family: inherit
// @param $border-style: solid
// @param $transition: false
// @param $transition-duration
// @param $outline: true,
// @param $outline-style: solid,
// @param $disabled-opacity: 1
// -------------------------
@mixin textarea-base(
  $display: block,
  $width: 100%,
  $font-family: inherit,
  $line-height: inherit,
  $border-style: null,
  $transition: false,
  $transition-duration: null,
  $outline: false,
  $outline-style: solid,
  $disabled-opacity: 1,
  $appearance: null
) {
  display: $display;
  width: $width;
  appearance: $appearance;
  border-style: $border-style;
  font-family: $font-family;
  line-height: $line-height;
  overflow: auto; // Per CSS3 UI, `resize` only applies when `overflow` isn't `visible`
  resize: vertical; // Options: horizontal, vertical, both

  @if ($transition == true) {
    transition-duration: $transition-duration;
    transition-property: background-color, color, border-color, outline-color;
  }

  @if ($outline == true) {
    outline-style: $outline-style;
  }

  @if ($outline == false) {
    &:focus {
      outline: 0;
    }
  }

  &[disabled],
  &.is-disabled,
  .is-disabled > & {
    cursor: no-drop;

    @if ($disabled-opacity != 1) {
      opacity: $disabled-opacity;
    }
  }
}
