//
// layout
// --------------------------------------------------

@import 'variables';

.#{$layout-class} {
  $this: &;

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  //
  // Elements
  // -------------------------

  &__sidebar {
    z-index: 10;
  }

  &__main {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  //
  // Modifiers
  // -------------------------

  &--dashboard {
    padding-left: rem($g-sidebar-width);
  }
}
