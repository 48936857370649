//
// Container
// --------------------------------------------------

@import 'variables';

.#{$container-class} {
  padding-left: rem($container-padding-horizontal);
  padding-right: rem($container-padding-horizontal);
  margin-left: auto;
  margin-right: auto;
  max-width: rem($container-max-width);

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------
}
