//
// orders\stab
// --------------------------------------------------

@import 'variables';

.#{$orders-tab-class} {
  $this: &;

  display: flex;
  height: rem(80px);
  background-color: color('purple', '800');
  padding-left: rem(52px);
  padding-right: rem(52px);
  border-bottom: 1px solid color('gray', '500');

  //
  // Elements
  // -------------------------

  &__menu {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__menu-item {
    display: flex;
    flex-direction: column;
  }

  &__menu-link {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(8px) rem(16px);
    color: color('gray', '200');
    text-decoration: none;

    &::before {
      content: '';
      position: absolute;
      left: rem(16px);
      right: rem(16px);
      height: 3px;
      bottom: -1px;
      background-color: color('white');
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      visibility: hidden;
      opacity: 0;
      transition-duration: $orders-tab-transition-duration;
      transition-property: opacity, visibility;
    }

    &.is-active,
    .is-active > &,
    &.active,
    .active > &,
    &:hover {
      color: color('white');
      text-decoration: none;

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  //
  // Modifiers
  // -------------------------
}
