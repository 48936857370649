//
// tag
// --------------------------------------------------

@import 'variables';

.#{$tag-class} {
  $this: &;

  display: inline-flex;
  align-items: center;
  padding: em(3.5px, $tag-font-size) em(14px, $tag-font-size);
  font-size: rem($tag-font-size);
  font-weight: 400;
  border-radius: (em(3.5px, $tag-font-size) * 2) + (em($tag-font-size) * $tag-line-height);
  border-width: 1px;
  line-height: $tag-line-height;
  border-style: solid;
  border-color: transparent;
  margin-bottom: rem(8px);

  &:not(:last-child) {
    margin-right: rem(8px);
  }

  //
  // Elements
  // -------------------------

  &__label {
    font-size: em($tag-font-size, $tag-font-size);
  }

  &__close-button {
    display: inline-flex;
    font-size: em(12px, $tag-font-size);
    padding: em(3.5px, $tag-font-size);
    border: 0;
    margin-left: em(3px, $tag-font-size);
    margin-right: em(-10px, $tag-font-size);
    background-color: transparent;
    color: currentColor;
    line-height: inherit;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: rgba(color('black'), 0.1);
    }
  }

  //
  // Modifiers
  // -------------------------

  &--light-gray {
    color: color('gray', '300');
    background-color: color('light-gray', '300');
    border-color: color('light-gray', '700');
  }

  &--gray {
    color: color('white');
    background-color: color('gray', '100');
    border-color: color('gray', '100');
  }

  &--purple {
    color: color('white');
    background-color: color('purple', '400');
    border-color: color('purple', '400');
  }

  &--blue {
    color: color('white');
    background-color: color('blue', '400');
    border-color: color('blue', '400');
  }

  &--green {
    color: color('white');
    background-color: color('green', '400');
    border-color: color('green', '400');
  }

  &--red {
    color: color('white');
    background-color: color('red', '400');
    border-color: color('red', '400');
  }

  &--orange {
    color: color('white');
    background-color: color('orange', '400');
    border-color: color('orange', '400');
  }

  &--yellow {
    color: color('white');
    background-color: color('yellow', '400');
    border-color: color('yellow', '400');
  }
}
