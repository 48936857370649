//
// Input
// -------------------------

@import 'mixins/input-base';
@import 'mixins/input-theme';
@import 'mixins/input-size';
@import 'variables';

.#{$input-class} {
  @include input-base(
    $transition: true,
    $border-style: solid,
    $outline: false,
    $transition-duration: $g-transition-duration
  );

  @include input-theme(
    $color: color('gray', '800'),
    $color-focus: color('gray', '800'),
    $color-valid: color('gray', '800'),
    $color-invalid: color('gray', '800'),
    $color-disabled: color('gray', '400'),
    $color-placeholder: color('light-gray', '800'),
    $background-color: transparent,
    $background-color-focus: transparent,
    $background-color-valid: color('green', '100'),
    $background-color-invalid: color('red', '100'),
    $background-color-disabled: color('light-gray', '300'),
    $border-color: color('light-gray', '600'),
    $border-color-focus: color('gray', '300'),
    $border-color-valid: color('green', '700'),
    $border-color-invalid: color('red', '700'),
    $border-color-disabled: color('gray', '100'),
    $outline: false,
    $outline-color: transparent,
    $outline-color-focus: color('blue', '100')
  );
  @include input-size(
    $font-size: 16px,
    $font-weight: 400,
    $padding-vertical: 10px,
    $padding-horizontal: 16px,
    $border-radius: 2px,
    $border-width: 1px,
    $outline: false,
    $outline-width: 1px,
    $outline-offset: 0,
    $height: 46px
  );

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------
}
