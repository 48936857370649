//
// Color
// --------------------------------------------------

.u-color {
  $this: &;

  @each $color, $value in $color-values {
    $values: get($color-values, $color);

    @if type-of($values) == 'map' {
      @each $weight, $weight-color in $values {
        &-#{$color}-#{$weight} {
          color: $weight-color;
        }
      }
    } @else {
      &-#{$color} {
        color: $value;
      }
    }
  }
}
