//
// Keyframes
// -------------------------

@keyframes hamburger-before-passive {
  0% {
    transform: translateY(0%) rotate(45deg);
  }

  50% {
    transform: translateY(0%) rotate(0deg);
  }

  100% {
    transform: translateY(-300%) rotate(0deg);
  }
}

@keyframes hamburger-after-passive {
  0% {
    transform: translateY(0%) rotate(-45deg);
  }

  50% {
    transform: translateY(0%) rotate(0deg);
  }

  100% {
    transform: translateY(300%) rotate(0deg);
  }
}

@keyframes hamburger-before-active {
  0% {
    transform: translateY(-300%) rotate(0deg);
  }

  50% {
    transform: translateY(0%) rotate(0deg);
  }

  100% {
    transform: translateY(0%) rotate(45deg);
  }
}

@keyframes hamburger-after-active {
  0% {
    transform: translateY(300%) rotate(0deg);
  }

  50% {
    transform: translateY(0%) rotate(0deg);
  }

  100% {
    transform: translateY(0%) rotate(-45deg);
  }
}
