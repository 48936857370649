//
// modal
// --------------------------------------------------

@import 'variables';

.#{$modal-class} {
  $this: &;

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $modal-zindex;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: $modal-transition-duration;
  padding: rem(32px);

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &.is-active > #{$this}__wrapper {
    transform: translate3d(0, 0, 0);
  }

  //
  // Elements
  // -------------------------

  &__close-button {
    display: inline-flex;
    position: fixed;
    right: rem(16px);
    top: rem(16px);
    background-color: transparent;
    border: 0;
    padding: rem(10px);
    font-size: rem(20px);
    margin: 0;
    border-radius: 50%;
    color: color('gray', '400');
    cursor: pointer;
    transition-property: color, background-color;
    transition-duration: $modal-transition-duration;
    background-color: rgba(color('white'), 0.025);

    &:hover {
      background-color: rgba(color('black'), 0.025);
      color: color('gray', '800');
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    padding: 0;
    margin: 0;
    background-color: rgba(color('black'), 0.5);
  }

  &__wrapper {
    width: rem(700px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: color('white');
    border-radius: rem(10px);
    overflow: hidden;

    .has-close-button & {
      min-height: 72px;
    }
  }

  &__body {
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: rem(40px);
  }

  //
  // Modifiers
  // -------------------------

  &--size {
    &-small #{$this}__wrapper {
      width: rem(500px);
    }

    &-medium #{$this}__wrapper {
      width: rem(700px);
    }

    &-large #{$this}__wrapper {
      width: rem(900px);
    }
  }
}
