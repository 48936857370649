//
// Button Base
//
// @param {String} $font-family
// @param {String, Number} $line-height
// @param {String} $border-style
// @param {Boolean} $outline
// @param {String} $outline-style
// @param {Boolean} $transition
// @param {String} $transition-duration
// @param {Number} $disabled-opacity
// @param {String} $text-transform
// -------------------------
@mixin button-base(
  $font-family: inherit,
  $line-height: inherit,
  $border-style: null,
  $outline: false,
  $outline-style: solid,
  $transition: false,
  $transition-duration: null,
  $disabled-opacity: 1,
  $text-transform: inherit,
  $text-decoration: inherit,
  $text-decoration-hover: none,
  $cursor: inherit
) {
  display: inline-block;
  font-family: $font-family;
  line-height: $line-height;
  text-align: center;
  text-decoration: $text-decoration;
  white-space: nowrap;
  border-style: $border-style;
  text-transform: $text-transform;
  cursor: pointer;

  @if ($outline == true) {
    outline-style: $outline-style;
  }

  @if ($transition == true) {
    transition-duration: $transition-duration;
    transition-property: background-color, color, border-color, outline;
  }

  &:hover,
  &.is-hover {
    text-decoration: $text-decoration-hover;
  }

  @if ($outline == false) {
    &:focus {
      outline: 0;
    }
  }

  &:disabled,
  &[disabled],
  &.is-disabled {
    opacity: $disabled-opacity;
    pointer-events: none;
  }
}
