//
// social links
// --------------------------------------------------

@import 'variables';

.#{$social-links-class} {
  $this: &;

  display: inline-flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  //
  // Elements
  // -------------------------

  &__item {
    &:not(:last-child) {
      margin-right: rem(12px);
    }
  }

  &__link {
    display: block;
    font-size: rem(24px);
    color: currentColor;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
      color: currentColor;
    }
  }

  &__icon {
    display: block;
  }

  //
  // Modifiers
  // -------------------------
}
